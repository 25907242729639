<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 3C0 2.33696 0.263392 1.70107 0.732233 1.23223C1.20107 0.763392 1.83696 0.5 2.5 0.5H17.5C18.163 0.5 18.7989 0.763392 19.2678 1.23223C19.7366 1.70107 20 2.33696 20 3V13C20 13.663 19.7366 14.2989 19.2678 14.7678C18.7989 15.2366 18.163 15.5 17.5 15.5H2.5C1.83696 15.5 1.20107 15.2366 0.732233 14.7678C0.263392 14.2989 0 13.663 0 13V3ZM2.5 1.75C2.16848 1.75 1.85054 1.8817 1.61612 2.11612C1.3817 2.35054 1.25 2.66848 1.25 3V3.27125L10 8.52125L18.75 3.27125V3C18.75 2.66848 18.6183 2.35054 18.3839 2.11612C18.1495 1.8817 17.8315 1.75 17.5 1.75H2.5ZM18.75 4.72875L12.865 8.26L18.75 11.8812V4.72875ZM18.7075 13.3237L11.6575 8.985L10 9.97875L8.3425 8.985L1.2925 13.3225C1.36353 13.5885 1.5204 13.8236 1.73874 13.9913C1.95708 14.159 2.22468 14.25 2.5 14.25H17.5C17.7752 14.25 18.0426 14.1593 18.261 13.9918C18.4793 13.8243 18.6362 13.5895 18.7075 13.3237ZM1.25 11.8812L7.135 8.26L1.25 4.72875V11.8812Z" />
  </svg>
</template>
